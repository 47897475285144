class GF {

    constructor() {
        // Move steps inbetween buttons
        jQuery('.gform_page_footer').each(function() {
            jQuery(this).children('.gf_page_steps').insertBefore(jQuery(this).children('.gform_button'));
            jQuery(this).children('.gf_page_steps').insertBefore(jQuery(this).children('.gform_next_button'));
        })

        // Remove bgcolor from review table and cells
        jQuery('.mfhs_review table').removeAttr('bgcolor');
        jQuery('.mfhs_review table tbody tr').removeAttr('bgcolor');


        // Change inline styled reveiw table
        jQuery('.mfhs_review table tbody tr td').css({'background-color': '', 'border': '0 none'});

        jQuery('.mfhs_review table tbody tr td').each(function() { 
            if(jQuery(this).css('font-size') === '14px') {
                jQuery(this).css({
                    'background-color': '',
                    'font-size': 'var(--fluid-h5)',
                    'padding': '2rem 0 1rem'
                });
            }

            jQuery(this).children('font').css('font-size', '15px');
        });
    }

}

export default GF;